<template>
  <div class="jurisdiction">
    <div class="tab_txt">
      <div class="input-with">
        <el-button type="primary" @click="userinfoClick">添加角色</el-button>
      </div>
      <el-table
        v-loading="loading"
        :data="listData"
        stripe
        style="width: 100%"
        class="in_table"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >/>
        <el-table-column label="编号" align="center" type="index">
          <template slot-scope="scope">
            <span>{{ (queryInfo.pageNum - 1) * queryInfo.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="角色名称" align="center">
          <template v-slot="{ row }">
            <span>{{ row.roleName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="角色描述" prop="description" align="center"></el-table-column>
        <el-table-column label="用户数量" prop="questionnaireName" align="center"></el-table-column>
        <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          width="260px"
        >
          <template slot-scope="scope">
            <el-button style="color: #146aff" type="text" @click="edidrole(scope.row)"
              >编辑
            </el-button>
            <el-button style="color: #ff4646" type="text" @click="deleterole(scope.row)"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog title="新增" :visible.sync="open" width="550px" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="88px">
        <el-form-item label="角色名:" prop="roleName">
          <el-input v-model="form.roleName" placeholder="请输入角色名" />
        </el-form-item>

        <el-form-item label="角色描述:" prop="description">
          <div class="txtree">
            <el-input
              v-model="form.description"
              type="textarea"
              :autosize="{ minRows: 3, maxRows: 4 }"
              placeholder="请输入内容"
            />
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="display: flex; justify-content: center">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { rolelist ,addrole,editrole,getrole,deleterole} from '@/api/system'
export default {
  name: 'Jurisdiction',
  data() {
    return {
      listData: [],
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      form: {
        authIdList: [],
        description: '',
        roleName: ''
      },
      //验证必填
      rules: {
        roleName: [{ required: true, message: '请输入角色名', trigger: 'blur' }],
        description: [{ required: true, message: '请输入角色描述', trigger: 'blur' }]
      },
      open: false,
      //查询角色一览
      queryInfo: {
        pageNum: 1,
        pageSize: 10,
        roleName: null
      }
    }
  },
  created() {
    this.search()
  },
  methods: {
    //查询角色一览
    async search() {
      this.loading = true
      const res = await rolelist(this.queryInfo)
      if (res.data.resultCode == 200) {
        this.listData = res.data.data.list
        this.total = res.data.data.total
        this.loading = false
      }
    },
    //打开新增角色
    userinfoClick() {
      this.open = true
      this.form = {
        authIdList: [],
        description: '',
        roleName: ''
      }
    },
    cancel() {
      this.open = false
    },
     //编辑获取角色详情
     edidrole(row) {
     
      this.open=true
      getrole({ id: row.id }).then((response) => {
        if (response.data.resultCode == 200) {
        this.form=response.data.data
        } 
      })
    },
     //删除系统用户
     deleterole(row){
      const ids = row.id 
      this.$alert('您是否确认删除？').then(function () {
        return deleterole({id:ids});
      }).then(() => {
        this.search()
        this.$message.success("删除成功");
      }).catch(() => { });
    },
    //新增编辑
    submitForm(){
        this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.id == null) {
            addrole(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('新增成功')
                this.open = false
                this.search()
              } else {
                this.$message.error(response.data.message)
              }
            })
          } else {
            editrole(this.form).then((response) => {
              if (response.data.resultCode == 200) {
                this.$message.success('编辑成功')
                this.open = false
                this.search()
              } else {
                this.$message.error(response.data.message)
              }
            })
          }
        }
      })
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.jurisdiction {
  .form_info {
    background: #ffffff;
    padding: 20px;
  }

  .tab_txt {
    background: #ffffff;
    margin-top: 20px;
    padding: 20px;
  }

  .span_div {
    font-size: 14px;
    color: #7c7f8e;
    margin-right: 12px;
  }

  .input-with {
    width: 100%;
    overflow: hidden;

    .input-with-select {
      width: 400px;
      float: right;
    }

    .seach {
      background-color: #448aff;
      color: #fff;
      border-radius: 0;
      border: 1px solid #448aff;
    }
  }

  .in_table {
    width: 100%;
    margin-top: 20px;

    .img_url {
      width: 42px;
      height: 42px;
      border-radius: 4px;
    }
  }

  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
}
</style>
