import request from "@/utils/request"
//查询角色中心列表
export function rolelist(data) {
    return request({
        url: "/admin_role/v1/list",
        method: "post",
        data:data
    })
}
//用户一览
export function adminuser(data) {
    return request({
        url: "/admin_user/v1/list",
        method: "post",
        data:data
    })
}
//新增系统用户
export function addysyuser(data) {
    return request({
        url: "/admin_user/v1/add",
        method: "post",
        data:data
    })
}
//编辑系统用户
export function editysyuser(data) {
    return request({
        url: "/admin_user/v1/edit",
        method: "post",
        data:data
    })
}
//不带分页查询所有角色
export function rolelistAll() {
    return request({
        url: "/admin_role/v1/listAll",
        method: "post",
       
    })
}
//根据用户id查询用户详情
export function getdetail(query) {
    return request({
        url: "/admin_user/v1/detail",
        method: "get",
        params:query
       
    })
}
//删除系统用户
export function deleteuser(query) {
    return request({
        url: "/admin_user/v1/delete",
        method: "get",
        params:query
       
    })
}
//新增角色
export function addrole(data) {
    return request({
        url: "/admin_role/v1/add",
        method: "post",
        data:data
    })
}
//编辑角色
export function editrole(data) {
    return request({
        url: "/admin_role/v1/edit",
        method: "post",
        data:data
    })
}
//角色详情
export function getrole(query) {
    return request({
        url: "/admin_role/v1/detail",
        method: "get",
        params:query
       
    })
}
//删除角色
export function deleterole(query) {
    return request({
        url: "/admin_role/v1/delete",
        method: "get",
        params:query
       
    })
}

